<template>
  <v-container grid-list-xl>
    <router-link tag="a" to="/dashboard" class="text-decoration-none">
      <v-icon>mdi-arrow-left</v-icon>
    </router-link>
   <v-row>
     <div class="matLeft-container" >
        <h5 class="mt-3 ml-2 text-uppercase mb-0">Vật tư </h5>
        <small class="ml-2">Click icon to select</small>
       <transition-group name="fade" tag="div" class="matLeft">
            <div
            class="item"
            
            v-for="(item, index) in items"
            :key="item.id"
            @click="selectItem(item, index)"
            >
                {{ item.name }}
            </div>
          </transition-group>
     </div>
     <v-col class="ml-2 use-mat">
        <v-row justify="space-between">
          <h4>Sử dụng</h4>
          <v-btn color="primary">Submit</v-btn>
        </v-row>
        <transition-group name="fade" tag="div" class="row">
          <div
            class="item"
            v-for="(item, index) in selected"
            :key="item.id"
            @click="unselectItem(item,index)"
          >
            {{ item.name }}
          </div>
        </transition-group>
      </v-col>
   </v-row>
   
      
    
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      items:[
	{
		"id": 1,
		"name": "Margalo",
		"unit": "Bach"
	},
	{
		"id": 2,
		"name": "Dorice",
		"unit": "Harriman"
	},
	{
		"id": 3,
		"name": "Agnese",
		"unit": "Raseda"
	},
	{
		"id": 4,
		"name": "Valeda",
		"unit": "Miru"
	},
	{
		"id": 5,
		"name": "Xylina",
		"unit": "Pitt"
	}
],
      selected: [],
    };
  },
  methods: {
    selectItem(item,index) {
      if (!this.selected.includes(item)) {
          this.items.splice(index,1);
            this.selected.push(item);
      } else console.log(item);
    },
    unselectItem(item,index) {
        if(!this.items.includes(item))
        {this.items.push(item);}
        this.selected.splice(index, 1);

    },
  },
};
</script>
<style lang="scss" scoped>
.matLeft-container{
  transition: all 0.3s linear;
  .matLeft{
  overflow-x: hidden;
  height: 85vh;
  overflow-y: auto;
  padding: 15px;
  width: max-content;
  background-color: #8BC34A; //material 500
      &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888; 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
}
}


.item {
  width: 150px;
  height: 150px;
  color: white;
  background-color: #795548;
  margin: 5px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  border: 4px solid gainsboro
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to  {
  transform: scale(0);
}
.use-mat{
  border-left: 2px dashed #607D8B;
  padding-left: 2rem;
}
</style>
